import { styled } from '@linaria/react';

export const Background = styled.div<{ dark: boolean }>`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  background-color: ${(props) => (props.dark ? 'var(--surface-always-dark)' : 'var(--surface)')};
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 568px;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  padding: 0 var(--spacing-md) var(--spacing-4xl) var(--spacing-md);

  body[data-mobile='true'] & {
    max-width: 468px;
    padding: 0 var(--spacing-md) var(--spacing-md) var(--spacing-md);
  }

  @media only screen and (max-height: 768px) {
    padding: var(--spacing-xs) var(--spacing-md) var(--spacing-md) var(--spacing-md);
  }
`;

export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 110%;
  transform: translateY(-10dvh);
`;
