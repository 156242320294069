import { PropsWithChildren, ReactNode } from 'react';

import LogoBackground from '@/assets/logo-background.svg';

import { Background, BackgroundImage, Container } from './RecordingLayoutRoot.styles';

type RecordingLayoutRootProps = PropsWithChildren<{
  Header?: ReactNode;
  dark?: boolean;
}>;

export function RecordingLayoutRoot({ dark = false, Header, children }: RecordingLayoutRootProps) {
  return (
    <Background dark={dark}>
      {dark && <BackgroundImage src={LogoBackground} alt="Logo background" />}

      <Container>
        {Header}
        {children}
      </Container>
    </Background>
  );
}
