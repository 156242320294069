import ImageSrc from '@/assets/referral-dialog.webp';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMDialog } from '@/components/RMDialog';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer } from '@/modules/form/container';
import { useIsFormValid } from '@/modules/form/form';

import { ReferralForm } from '../../referral.form';

import {
  Body,
  BodyContent,
  BodyImage,
  CloseButton,
  Input,
  InputButtonGroup,
  InputButtonGroupButton,
  InputButtonGroupInput,
  RMDialogContent,
} from './ReferralDialog.styles';

export interface ReferralDialogProps {
  form: ReferralForm;
  link: string;
  onCopyLink: () => void;
  onSendEmail: () => void;
  onShare: () => void;
  onClose: () => void;
}

export function ReferralDialog({ form, link, onCopyLink, onSendEmail, onShare, onClose }: ReferralDialogProps) {
  const isMobile = useIsMobileViewport();
  const isFormValid = useIsFormValid(form);

  return (
    <RMDialog.Root open onClose={onClose} variant="large">
      <RMDialogContent>
        <Body>
          <CloseButton onClick={onClose} />
          <BodyImage src={ImageSrc} />
          <BodyContent>
            <RMText type="serif" size="xl" color="on-surface-primary" align="center">
              Get a <b>Free Book Credit</b>
            </RMText>
            <RMText type="serif" size="l" color="on-surface-primary" align="center">
              For Every Friend You Refer
            </RMText>
            <RMSpacer direction="column" spacing="xl" />
            <RMText type="sans" size="s" color="on-surface-primary" align="center">
              Give your friends $15 off and you’ll get a credit for a hardcover book when they purchase.
            </RMText>
            {isMobile == false && (
              <>
                <RMSpacer direction="column" spacing="2xl" />
                <RMText type="sans" size="xs" color="on-surface-primary" align="center">
                  Share your referral link anywhere:
                </RMText>
                <RMSpacer direction="column" spacing="2xs" />
                <InputButtonGroup>
                  <InputButtonGroupInput id="referral-form-link" value={link} readOnly disabled />
                  <InputButtonGroupButton background="transparent" onClick={onCopyLink}>
                    Copy
                  </InputButtonGroupButton>
                </InputButtonGroup>

                <RMSpacer direction="column" spacing="xl" />
                <RMText type="sans" size="xs" color="on-surface-primary" align="center">
                  Send your friend an email:
                </RMText>
                <RMSpacer direction="column" spacing="2xs" />
                <InputContainer form={form} path="email">
                  {(props) => <Input id="referral-form-email" placeholder="Send to" {...props} />}
                </InputContainer>
              </>
            )}

            <RMSpacer direction="column" spacing="xl" />

            <RMButton
              background="primary"
              fullWidth
              autoLoading
              disabled={!isFormValid && !isMobile}
              onClick={isMobile ? onShare : onSendEmail}
            >
              {isMobile ? 'Share' : 'Send'}
            </RMButton>
          </BodyContent>
        </Body>
      </RMDialogContent>
    </RMDialog.Root>
  );
}
