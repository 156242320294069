import { useCallback, useMemo } from 'react';

import { toast } from '@/components/RMToast/RMToast';
import { resetForm, submitForm } from '@/modules/form/form';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { useReferralLink as useReferralLinkQuery } from '@/services/api/referral/referral.service.hooks';
import { captureException } from '@/utils/captureException';
import { writeToClipboard } from '@/utils/clipboard';
import { openShareSheet, showShareSheetToast } from '@/utils/share-sheet';

import { ReferralDialog } from '../components/ReferralDialog/ReferralDialog';
import { createReferralForm } from '../referral.form';
import { closeReferralDialog, useIsReferralDialogOpen } from '../referral.state';
import { useReferralStore } from '../referral.state.provider';

export function ReferralDialogContainer() {
  // Services
  const { referralService, referralAnalyticsService } = useServices();

  // Queries
  const user = useUser();
  const referralLinkQuery = useReferralLinkQuery();

  // State
  const referralStore = useReferralStore();
  const isOpen = useIsReferralDialogOpen(referralStore);
  const form = useMemo(() => createReferralForm(), []);

  // Callbacks
  const handleClose = useCallback(() => {
    closeReferralDialog(referralStore);
  }, [referralStore]);

  const handleCopyLink = useCallback(async () => {
    try {
      await writeToClipboard(referralLinkQuery.data ?? '');
      toast('Link copied to the clipboard');
      referralAnalyticsService.onReferralShared('clipboard');
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [referralAnalyticsService, referralLinkQuery.data]);

  const handleSendEmail = useCallback(async () => {
    await submitForm(form, async ({ email }) => {
      try {
        await referralService.sendReferralEmail(email);
        referralAnalyticsService.onReferralShared('email');
        toast('Referral email sent!');
        handleClose();
        resetForm(form);
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    });
  }, [form, handleClose, referralAnalyticsService, referralService]);

  const handleShare = useCallback(async () => {
    try {
      const shareResult = await openShareSheet({
        url: referralLinkQuery.data ?? '',
        title: 'Fancy $15 off a Remento book?',
        text: [
          '',
          'I’ve been using Remento to document family memories, and thought you might like to also.',
          '',
          'Here’s $15 off your first purchase, so you can document precious family memories and stories - all without writing a single word.',
        ].join('\n'),
      });
      showShareSheetToast(shareResult);
      referralAnalyticsService.onReferralShared('share-sheet');
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [referralAnalyticsService, referralLinkQuery.data]);

  if (!isOpen || user == null) {
    return null;
  }

  return (
    <ReferralDialog
      form={form}
      link={referralLinkQuery.data ?? 'Loading...'}
      onCopyLink={handleCopyLink}
      onSendEmail={handleSendEmail}
      onShare={handleShare}
      onClose={handleClose}
    />
  );
}
