import { datadogLogs } from '@datadog/browser-logs';

interface Logger {
  debug(message: string, messageContext?: object, error?: Error): void;
  info(message: string, messageContext?: object, error?: Error): void;
  warn(message: string, messageContext?: object, error?: Error): void;
  error(message: string, messageContext?: object, error?: Error): void;
}

class DatadogLogger implements Logger {
  debug(message: string, messageContext?: object, error?: Error): void {
    datadogLogs.logger.debug(message, { context: messageContext }, error);
  }

  info(message: string, messageContext?: object, error?: Error): void {
    datadogLogs.logger.info(message, { context: messageContext }, error);
  }

  warn(message: string, messageContext?: object, error?: Error): void {
    datadogLogs.logger.warn(message, { context: messageContext }, error);
  }

  error(message: string, messageContext?: object, error?: Error): void {
    datadogLogs.logger.error(message, { context: messageContext }, error);
  }
}

class ConsoleLogger implements Logger {
  debug(message: string, messageContext?: object, error?: Error): void {
    console.debug(message, messageContext, error);
  }

  info(message: string, messageContext?: object, error?: Error): void {
    console.info(message, messageContext, error);
  }

  warn(message: string, messageContext?: object, error?: Error): void {
    console.warn(message, messageContext, error);
  }

  error(message: string, messageContext?: object, error?: Error): void {
    console.error(message, messageContext, error);
  }
}

class RemoteLogger implements Logger {
  constructor(private url: string) {}

  private sendLog(level: string, message: string, messageContext?: object, error?: Error) {
    fetch(this.url, {
      method: 'POST',
      body: JSON.stringify({
        level,
        message,
        messageContext,
        error,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
  }

  debug(message: string, messageContext?: object, error?: Error): void {
    this.sendLog('debug', message, messageContext, error);
  }

  info(message: string, messageContext?: object, error?: Error): void {
    this.sendLog('info', message, messageContext, error);
  }

  warn(message: string, messageContext?: object, error?: Error): void {
    this.sendLog('warn', message, messageContext, error);
  }

  error(message: string, messageContext?: object, error?: Error): void {
    this.sendLog('error', message, messageContext, error);
  }
}

class AggregateLogger implements Logger {
  constructor(private readonly loggers: Logger[]) {}

  debug(message: string, messageContext?: object, error?: Error): void {
    this.loggers.forEach((logger) => logger.debug(message, messageContext, error));
  }

  info(message: string, messageContext?: object, error?: Error): void {
    this.loggers.forEach((logger) => logger.info(message, messageContext, error));
  }

  warn(message: string, messageContext?: object, error?: Error): void {
    this.loggers.forEach((logger) => logger.warn(message, messageContext, error));
  }

  error(message: string, messageContext?: object, error?: Error): void {
    this.loggers.forEach((logger) => logger.error(message, messageContext, error));
  }
}

const loggers: Logger[] = [];

if (import.meta.env.PROD) {
  loggers.push(new DatadogLogger());
}

const url = new URL(window.location.toString());
if (import.meta.env.DEV || url.searchParams.get('debug') === 'true') {
  loggers.push(new ConsoleLogger());
}

const remoteLoggerUrl = url.searchParams.get('remote-logger');
if (remoteLoggerUrl != null) {
  loggers.push(new RemoteLogger(remoteLoggerUrl));
}

export const logger = new AggregateLogger(loggers);
