import UAParser from 'ua-parser-js';

export function isDesktopDevice() {
  // https://github.com/faisalman/ua-parser-js/issues/182
  const uaParser = new UAParser(navigator.userAgent);
  const device = uaParser.getDevice();
  return device.type === undefined || !['wearable', 'mobile', 'tablet'].includes(device.type);
}

export function isIpad() {
  return /iPad/i.test(navigator.userAgent);
}

export function isIphone() {
  return ['iPhone Simulator', 'iPhone'].includes(navigator.platform);
}
